import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Container, Row, Col } from "react-bootstrap"
import { RiArrowRightSLine } from "react-icons/ri"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { useIntl, Link } from "gatsby-plugin-intl"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ThePage = () => {
  const data = useStaticQuery(graphql`
    query childpsyQuery {
      img1: file(relativePath: { eq: "childpsy01.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 695) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      img2: file(relativePath: { eq: "childpsy02.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 695) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `)

  const intl = useIntl()
  const tt = key => {
    if (intl.messages[key] === undefined) {
      return key
    } else if (intl.messages[key] === "") {
      return ""
    } else {
      return intl.formatMessage({ id: key })
    }
  }

  const showNonEnContents = () => {
    if (intl.locale !== "en")
      return (
        <>
          <p>{tt("pages.childpsy.desc3_2")}</p>
          <ul>
            <li>{tt("pages.childpsy.desc3_3")}</li>
          </ul>
          <p>{tt("pages.childpsy.desc3_4")}</p>
          <ul>
            <li>{tt("pages.childpsy.desc3_5")}</li>
            <li>{tt("pages.childpsy.desc3_6")}</li>
          </ul>
        </>
      )
    else return <></>
  }

  console.log(intl)
  return (
    <Layout>
      <SEO title="seo.title.ChildPsy" description="seo.desc.ChildPsy" />
      <div className="content-master">
        <Container>
          <Row>
            <Col xs={12}>
              <h3>
                <div
                  css={css`
                    text-align: center;
                    font-weight: bold;
                  `}
                >
                  {tt("pages.childpsy.title1")}
                </div>
              </h3>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-dark">{tt("pages.childpsy.desc1")}</div>
            </Col>
          </Row>
          <Row>
            <Col sm={6} className="pt-4">
              <div className="d-flex flex-column">
                <div className="font-weight-bold">
                  {tt("pages.childpsy.qualification1_title")}
                </div>
                <div css={css``} className="text-dark">
                  {/*
                  <div className="d-flex flex-row">
                    <div className="pr-3">1987</div>
                    <div>{tt("pages.childpsy.qualification1_1")}</div>
                  </div>
                  <div className="d-flex flex-row">
                    <div className="pr-3">1992</div>
                    <div>{tt("pages.childpsy.qualification1_2")}</div>
                  </div>
                  */}
                  <div className="d-flex flex-row">
                    <div className="pr-3">2006</div>
                    <div>{tt("pages.childpsy.qualification1_3")}</div>
                  </div>
                  <div className="d-flex flex-row">
                    <div className="pr-3">2011</div>
                    <div>{tt("pages.childpsy.qualification1_4")}</div>
                  </div>
                  <div className="d-flex flex-row">
                    <div className="pr-3">2012</div>
                    <div>{tt("pages.childpsy.qualification1_5")}</div>
                  </div>
                </div>
              </div>
            </Col>
            <Col sm={6} className="pt-4">
              <div className="d-flex flex-column">
                <div className="font-weight-bold">
                  {tt("pages.childpsy.qualification2_title")}
                </div>
                <div css={css``} className="text-dark">
                  <div className="d-flex flex-row">
                    <div className="pr-3">2004</div>
                    <div>{tt("pages.childpsy.qualification2_1")}</div>
                  </div>
                  <div className="d-flex flex-row">
                    <div className="pr-3">2010</div>
                    <div>{tt("pages.childpsy.qualification2_2")}</div>
                  </div>
                  <div className="d-flex flex-row">
                    <div className="pr-3">2010</div>
                    <div>{tt("pages.childpsy.qualification2_3")}</div>
                  </div>
                  <div className="d-flex flex-row">
                    <div className="pr-3">2011</div>
                    <div>{tt("pages.childpsy.qualification2_4")}</div>
                  </div>
                  <div className="d-flex flex-row">
                    <div className="pr-3">2012</div>
                    <div>{tt("pages.childpsy.qualification2_5")}</div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="pt-4">
              <div className="font-weight-bold">
                {tt("pages.childpsy.title2")}
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={8}>
              <div className="pt-3 text-dark">{tt("pages.childpsy.desc2")}</div>
            </Col>
            <Col>
              <Img
                className="rounded mx-auto d-block border border-warning"
                fluid={data.img1.childImageSharp.fluid}
              />
            </Col>
          </Row>
          <Row>
            <Col className="pt-4">
              <div className="font-weight-bold">
                {tt("pages.childpsy.title3")}
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={8}>
              <div className="pt-3 text-dark">
                <p>{tt("pages.childpsy.desc3_1")}</p>
                {showNonEnContents()}
              </div>
            </Col>
            <Col>
              <Img
                className="rounded mx-auto d-block border border-warning"
                fluid={data.img2.childImageSharp.fluid}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  )
}

export default ThePage
